<template>
  <transition name="modal">
    <div class="modal-mask" @click="hide()">
      <div class="modal-container" v-if="getModalInfo"
           @click.stop>
        <div class="close-div">
          <img @click="hide()"
               src="https://gcdn.bionluk.com/site/icon/tansel_modal_close_ince.svg">
        </div>


        <div v-if="order && order.id" class="order-info-modal-body">
          <OrderBox from="orderInfoModal" :list="summaryBox"></OrderBox>
        </div>
        <loader v-else></loader>
      </div>
    </div>
  </transition>

</template>

<script>


import OrderBox from "../../components/boxSliders/order";

  export default {
    name: "src-pages-modals-forderinfo-v1",
    components:{OrderBox},
    data() {
      return {
        order:{
          id:null
        },
        summaryBox:[],
        id: null
      }
    },
    methods: {
      hide() {
        this.$store.commit(this.types.DISABLE_MODAL);
      },

      getOrder() {
        this.api.orders.getOrderv2(this.id)
        .then(({data}) => {
          let result = data;

          if (result.success) {
            this.order = result.data.order
            this.summaryBox.push(result.data.summary_box);
          } else {
            this.$toasted.global.errorToast({description: result.message});
          }
        })
        .catch(err => {
          this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
        });
      }
    },

    computed: {
      getModalInfo() {
        if (!this.id) {

          this.id = this.$store.state.activeModal.info;
          this.getOrder();
        }
        return true;
      },
    },

    created() {
    }
  }

</script>

<style scoped lang="scss">

  .modal-mask {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    background-color: rgba(0, 0, 0, .8);
  }

  .portfolio-modal-close-button {
    position: absolute;
    top: 20px;
    right: 160px;
    z-index: 999799 !important;
    cursor: pointer;
    width: 24px;
    height: 24px;
  }

  .modal-container {
    font-family: 'sofia-pro';
    margin: auto;
    padding: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: calc(100vw - 20px) !important;
    height: calc(100vh - 20px);
    max-width: 895px;
    max-height: 430px;
    background-color: #ffffff;
    box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.15);

    overflow-y: scroll;
    position: relative;
  }

  .close-div {
    position: absolute;
    top: 16px;
    right: 16px;

    img {
      cursor: pointer;
      width: 16px;
      height: 16px;
    }
  }



</style>
